import { API, graphqlOperation } from "aws-amplify";

import query from "./graphql";
import generateErrorMessage from "../../../utils/generateErrorMessage";

const scanSyncIndex = async () => {
  let nextToken = null;
  const data = [];

  try {
    do {
      // eslint-disable-next-line no-await-in-loop
      const response = await API.graphql(
        graphqlOperation(query, { after: nextToken })
      );
      nextToken = response.data.scanAdminIndex.nextToken;
      data.push(...response.data.scanAdminIndex.items);
    } while (nextToken);
    return data;
  } catch (e) {
    throw generateErrorMessage(e.errors);
  }
};

export default scanSyncIndex;
