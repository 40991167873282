import { API, graphqlOperation } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";

import mutation from "./graphql";
import generateErrorMessage from "../../../utils/generateErrorMessage";

const superAdminCreateCompany = async (name) => {
  try {
    const response = await API.graphql(
      graphqlOperation(mutation, { name, id: uuidv4() })
    );
    return response.data.superAdminCreateCompany;
  } catch (e) {
    throw generateErrorMessage(e.errors);
  }
};

export default superAdminCreateCompany;
