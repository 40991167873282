import gql from "graphql-tag";

import { jobFragment } from "../../../fragments";

export default gql`
  query superAdminQueryDateTimeData(
    $after: ID
    $companyId: ID!
    $from: AWSDateTime
    $to: AWSDateTime
    $type: DateTimeDataTypes
  ) {
    superAdminQueryDateTimeData(
      after: $after
      companyId: $companyId
      from: $from
      to: $to
      type: $type
    ) {
      __typename
      nextToken
      items {
        ... on Job {
          ...jobFragment
        }
      }
    }
  }
  ${jobFragment}
`;
