import { API, graphqlOperation } from "aws-amplify";

import query from "./graphql";
import generateErrorMessage from "../../../utils/generateErrorMessage";

const superAdminGetCompanyUserUsernameAlias = async ({ userId }) => {
  try {
    const { data } = await API.graphql(graphqlOperation(query, { userId }));
    return data.superAdminGetCompanyUserUsernameAlias;
  } catch ({ errors }) {
    throw generateErrorMessage(errors);
  }
};

export default superAdminGetCompanyUserUsernameAlias;
