import { API, graphqlOperation } from "aws-amplify";

import query from "./graphql";
import generateErrorMessage from "../../../utils/generateErrorMessage";

const superAdminSyncCompany = async ({ companyId, lastSync }) => {
  try {
    let nextToken = null;
    const data = [];
    do {
      // eslint-disable-next-line no-await-in-loop
      const response = await API.graphql(
        graphqlOperation(query, { companyId, lastSync, after: nextToken })
      );
      nextToken = response.data.superAdminSyncCompany.nextToken;
      data.push(...response.data.superAdminSyncCompany.items);
    } while (nextToken);

    return data;
  } catch (e) {
    throw generateErrorMessage(e.errors);
  }
};

export default superAdminSyncCompany;
