import React from "react";
import { withAuthenticator, AmplifySignOut } from "@aws-amplify/ui-react";
import Amplify from "aws-amplify";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Provider, useSelector } from "react-redux";
import LinearProgress from "@material-ui/core/LinearProgress";
import Snackbar from "@material-ui/core/Snackbar";

import Home from "./routes/Home";
import store from "./store";
import NewCompany from "./routes/NewCompany";
import CompanyDetails from "./routes/CompanyDetails";
import NewUser from "./routes/NewUser";
import config from "./config";

Amplify.configure(config);

const App = () => {
  const loading = useSelector((state) => state.data.loading);
  const error = useSelector((state) => state.data.error);
  return (
    <>
      <AmplifySignOut />
      {loading && <LinearProgress />}
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/new" component={NewCompany} />
          <Route exact path="/:companyId" component={CompanyDetails} />
          <Route exact path="/:companyId/users/new" component={NewUser} />
          <Route component={() => <div>404 Not found</div>} />
        </Switch>
      </Router>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={Boolean(error)}
        autoHideDuration={6000}
        message={error}
      />
    </>
  );
};

const ReduxWrapper = () => {
  return (
    <Provider store={store}>
      <App />
    </Provider>
  );
};

export default withAuthenticator(ReduxWrapper);
