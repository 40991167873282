import Card from "@material-ui/core/Card";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import Divider from "@material-ui/core/Divider";
import Fab from "@material-ui/core/Fab";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import List from "@material-ui/core/List";
import { makeStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import superAdminQueryDateTimeData from "../../api/functions/superAdminQueryDateTimeData";
import superAdminUpdateCompany from "../../api/functions/superAdminUpdateCompany";

import UserListItem from "../../components/UserListItem";
import { syncData } from "../../store/data";
import selectCompanyData from "../../store/data/selectors/selectCompanyData";

const useStyles = makeStyles(() => ({
  card: {
    marginBottom: 16,
    marginTop: 16,
    padding: 16,
  },
  container: {
    display: "flex",
    flexDirection: "column",
    maxWidth: 400,
    width: "100%",
    margin: 16,
  },
  fab: {
    right: 20,
    bottom: 20,
    position: "fixed",
  },
  list: {
    width: "100%",
  },
  root: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
  },
}));

const Home = () => {
  const history = useHistory();
  const { companyId } = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { company, customers, jobSites, users } = useSelector(
    selectCompanyData
  );

  const [historicalData, setHistoricalData] = useState();

  const [companyEnabled, setCompanyEnabled] = useState(false);
  const [adminUpdateCompanyPending, setAdminUpdateCompanyPending] = useState(
    false
  );

  useEffect(() => {
    if (companyId) dispatch(syncData({ companyId }));
  }, [dispatch, companyId]);
  useEffect(() => {
    if (companyId) {
      (async () => {
        setHistoricalData(
          await superAdminQueryDateTimeData({
            companyId,
            from: dayjs().subtract(30, "days").toISOString(),
            to: dayjs().toISOString(),
            type: "History",
          })
        );
      })();
    }
  }, [companyId]);
  useEffect(() => {
    if (company) {
      setCompanyEnabled(!company.isDisabled);
    }
  }, [company]);

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        {company && (
          <>
            <Typography variant="h6">{company.name}</Typography>
            <FormControlLabel
              control={
                <Checkbox
                  checked={companyEnabled}
                  color="primary"
                  disabled={adminUpdateCompanyPending}
                  onChange={async (event, checked) => {
                    setCompanyEnabled(checked);
                    try {
                      await superAdminUpdateCompany({
                        id: companyId,
                        isDisabled: !checked,
                      });
                      dispatch(syncData({ companyId }));
                    } catch (error) {
                      console.warn(error);
                      setCompanyEnabled(!company.isDisabled);
                    }
                  }}
                />
              }
              label="Enabled"
            />
          </>
        )}
        <Card className={classes.card}>
          <Typography>{`Customers: ${
            Object.values(customers).length
          }`}</Typography>
          <Typography>{`Job sites: ${
            Object.values(jobSites).length
          }`}</Typography>
          <Typography>{`Users: ${Object.values(users).length}`}</Typography>
          <Typography>{`Finished jobs (past 7 days): ${
            Array.isArray(historicalData)
              ? historicalData.filter((job) =>
                  dayjs(job.startDateTime).isAfter(dayjs().subtract(7, "days"))
                ).length
              : 0
          }`}</Typography>
          <Typography>{`Finished jobs (past 30 days): ${
            Array.isArray(historicalData) ? historicalData.length : 0
          }`}</Typography>
        </Card>
        <Typography variant="overline">Users</Typography>
        <List className={classes.list}>
          {Object.values(users).map((user) => (
            <div key={user.id}>
              <UserListItem user={user} />
              <Divider component="li" />
            </div>
          ))}
        </List>
      </div>
      <Fab
        className={classes.fab}
        color="primary"
        onClick={() => history.push(`/${companyId}/users/new`)}
      >
        <AddIcon />
      </Fab>
    </div>
  );
};

export default Home;
