import { API, graphqlOperation } from "aws-amplify";

import mutation from "./graphql";
import generateErrorMessage from "../../../utils/generateErrorMessage";

const superAdminCreateCompany = async ({
  firstName,
  lastName,
  role,
  companyId,
  phone,
  email,
  unmeteredUsage,
}) => {
  try {
    const response = await API.graphql(
      graphqlOperation(mutation, {
        firstName,
        lastName,
        role,
        companyId,
        phone,
        email,
        unmeteredUsage,
      })
    );
    return {
      __typename: "CompanyUser",
      ...response.data.superAdminCreateCompanyUser,
    };
  } catch (e) {
    throw generateErrorMessage(e.errors);
  }
};

export default superAdminCreateCompany;
