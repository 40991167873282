import gql from "graphql-tag";

import { companyFragment } from "../../../fragments";

export default gql`
  mutation superAdminCreateCompany($name: String!, $id: ID!) {
    superAdminCreateCompany(name: $name, id: $id) {
      ...companyFragment
    }
  }
  ${companyFragment}
`;
