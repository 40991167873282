import { API, graphqlOperation } from "aws-amplify";

import generateErrorMessage from "../../../utils/generateErrorMessage";
import mutation from "./graphql";

const superAdminUpdateCompany = async ({ id, isDisabled }) => {
  try {
    const { data } = await API.graphql(
      graphqlOperation(mutation, { id, isDisabled })
    );
    return data.superAdminUpdateCompany;
  } catch (error) {
    throw generateErrorMessage(error.errors);
  }
};

export default superAdminUpdateCompany;
