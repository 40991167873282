const prod = {
  Auth: {
    identityPoolId: "us-west-2:cdf1f4f2-0416-4ac3-937f-be0119cd21d9",
    region: "us-west-2",
    userPoolId: "us-west-2_HF4HBHMj5",
    userPoolWebClientId: "45f9ad23dsdv6c88f4tcrukqar",
  },
  aws_appsync_graphqlEndpoint:
    "https://phmrdcghujh5le3ka6w6efu3sq.appsync-api.us-west-2.amazonaws.com/graphql",
  aws_appsync_region: "us-west-2",
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
};

const staging = {
  Auth: {
    identityPoolId: "us-west-2:48d0d3f8-4536-4921-86f2-f51c396bb53a",
    region: "us-west-2",
    userPoolId: " us-west-2_QimzNmNuQ",
    userPoolWebClientId: "79cf1u21738888fa7n6nrbd39b",
  },
  aws_appsync_graphqlEndpoint:
    "https://aoutxyytjnfp3cqzvz6rrfoskq.appsync-api.us-west-2.amazonaws.com/graphql",
  aws_appsync_region: "us-west-2",
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
};

const dev = {
  Auth: {
    identityPoolId: "us-west-2:f219be80-c35b-47b5-9c5a-f26ffdd68a69",
    region: "us-west-2",
    userPoolId: "us-west-2_jJ8Jk4eEx",
    userPoolWebClientId: "maa757428s835bg3aci7edqb3",
  },
  aws_appsync_graphqlEndpoint:
    "https://n73hwv67kff3hkisyxdvo2l6mm.appsync-api.us-west-2.amazonaws.com/graphql",
  aws_appsync_region: "us-west-2",
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
};

// eslint-disable-next-line import/no-mutable-exports
let config = dev;

// DO NOT CHANGE THESE
if (process.env.REACT_APP_STAGE === "prod") config = prod;
else if (process.env.REACT_APP_STAGE === "staging") config = staging;
else if (process.env.REACT_APP_STAGE === "dev") config = dev;

export default config;
