import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
} from "@reduxjs/toolkit";

import scanSyncIndex from "../../api/functions/scanSyncIndex";
import superAdminSyncCompany from "../../api/functions/superAdminSyncCompany";

const adapter = createEntityAdapter();

export const syncCompanies = createAsyncThunk("data/companies", async () => {
  return scanSyncIndex();
});

export const syncData = createAsyncThunk("data/sync", async ({ companyId }) => {
  return superAdminSyncCompany({ companyId });
});

const initialState = {
  data: adapter.getInitialState(),
  companies: adapter.getInitialState(),
  loading: false,
  error: "",
};

const dataSlice = createSlice({
  name: "data",
  initialState,
  extraReducers: {
    [syncData.pending]: (state) => {
      state.loading = true;
      state.error = "";
    },
    [syncData.fulfilled]: (state, action) => {
      const { payload } = action;
      state.loading = false;
      state.error = "";
      state.data = adapter.setAll(state.data, payload);
    },
    [syncData.rejected]: (state) => {
      state.loading = false;
      state.error = "An unexpected error occurred. Please try again.";
    },
    [syncCompanies.pending]: (state) => {
      state.loading = true;
      state.error = "";
    },
    [syncCompanies.fulfilled]: (state, action) => {
      const { payload } = action;
      state.loading = false;
      state.error = "";
      state.companies = adapter.setAll(state.data, payload);
    },
    [syncCompanies.rejected]: (state) => {
      state.loading = false;
      state.error = "An unexpected error occurred. Please try again.";
    },
  },
  reducers: {
    setCompany: (state, action) => {
      const { payload } = action;
      const { company } = payload;
      state.companies = adapter.upsertOne(state.companies, company);
    },
    setData: (state, action) => {
      const { payload } = action;
      const { data } = payload;
      state.data = adapter.upsertOne(state.data, data);
    },
  },
});

const { actions, reducer } = dataSlice;

export const { setCompany, setData } = actions;

export default reducer;
