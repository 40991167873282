import gql from "graphql-tag";

const assigneeFragment = gql`
  fragment assigneeFragment on Assignee {
    id
    type
  }
`;

const userRecordFragment = gql`
  fragment userRecordFragment on UserRecord {
    at
    by
  }
`;

export const companyFragment = gql`
  fragment companyFragment on Company {
    name
    id
    syncDateTime
    created {
      ...userRecordFragment
    }
    updated {
      ...userRecordFragment
    }
    isDisabled
  }
  ${userRecordFragment}
`;

export const companyGroupFragment = gql`
  fragment companyGroupFragment on CompanyGroup {
    id
    members
    name
    syncDateTime
    created {
      ...userRecordFragment
    }
    updated {
      ...userRecordFragment
    }
    isDeleted
  }
  ${userRecordFragment}
`;

const coordinatesFragment = gql`
  fragment coordinatesFragment on Coordinates {
    lat
    lng
    accuracy
  }
`;

export const companyUserFragment = gql`
  fragment companyUserFragment on CompanyUser {
    id
    firstName
    lastName
    created {
      ...userRecordFragment
    }
    updated {
      ...userRecordFragment
    }
    coordinates {
      ...coordinatesFragment
    }
    locationUpdatedAt
    syncDateTime
    role
    speed
    heading
    isOnline
    isArchived
  }
  ${coordinatesFragment}
  ${userRecordFragment}
`;

const locationRecordFragment = gql`
  fragment locationRecordFragment on LocationRecord {
    at
    coordinates {
      ...coordinatesFragment
    }
  }
  ${coordinatesFragment}
`;

const mediaFragment = gql`
  fragment mediaFragment on Media {
    id
    description
    createdAt
    type
  }
`;

const userLocationRecordFragment = gql`
  fragment userLocationRecordFragment on UserLocationRecord {
    at
    by
    coordinates {
      ...coordinatesFragment
    }
  }
  ${coordinatesFragment}
`;

export const dailyActivityReportFragment = gql`
  fragment dailyActivityReportFragment on DailyActivityReport {
    id
    jobId
    submitted {
      ...userRecordFragment
    }
    updated {
      ...userRecordFragment
    }
    created {
      ...userRecordFragment
    }
    started {
      ...locationRecordFragment
    }
    completed {
      ...locationRecordFragment
    }
    summary
    media {
      ...mediaFragment
    }
    syncDateTime
  }
  ${locationRecordFragment}
  ${mediaFragment}
  ${userRecordFragment}
`;

export const incidentReportFragment = gql`
  fragment incidentReportFragment on IncidentReport {
    id
    jobId
    submitted {
      ...userRecordFragment
    }
    updated {
      ...userRecordFragment
    }
    created {
      ...userRecordFragment
    }
    incidentReportStartDateTime: startDateTime
    incidentReportEndDateTime: endDateTime
    summary
    policeCase {
      agency
      number
    }
    subjects {
      name
      sex
      ethnicity
      race
      phoneNumber
      address
      license {
        number
        region
      }
      description
      type
      height
      weight
      dateOfBirth
      age
      hairColor
      eyeColor
    }
    vehicles {
      description
      make
      model
      year
      color
      license {
        number
        region
      }
      vin
      registeredOwner
      type
      status
    }
    media {
      createdAt
      description
      type
      id
    }
    syncDateTime
  }
  ${userRecordFragment}
`;

const taskFragment = gql`
  fragment taskFragment on Task {
    id
    description
  }
`;

export const startJobRecordFragment = gql`
  fragment startJobRecordFragment on StartJobRecord {
    record {
      ...userLocationRecordFragment
    }
  }
  ${userLocationRecordFragment}
`;

export const taskCompletionRecordFragment = gql`
  fragment taskCompletionRecordFragment on TaskCompletionRecord {
    taskId
    record {
      ...userLocationRecordFragment
    }
  }
  ${userLocationRecordFragment}
`;

export const jobFragment = gql`
  fragment jobFragment on Job {
    id
    startDateTime
    endDateTime
    siteId
    syncId
    assignedTo {
      ...assigneeFragment
    }
    updated {
      ...userRecordFragment
    }
    created {
      ...userRecordFragment
    }
    tasks {
      ...taskFragment
    }
    finished {
      status
      record {
        ...userLocationRecordFragment
      }
    }
    startJobRecords {
      ...startJobRecordFragment
    }
    taskCompletionRecords {
      ...taskCompletionRecordFragment
    }
    syncDateTime
    isDeleted
    rrule
    processed
    immediate
  }
  ${taskCompletionRecordFragment}
  ${startJobRecordFragment}
  ${assigneeFragment}
  ${taskFragment}
  ${userLocationRecordFragment}
  ${userRecordFragment}
`;

export const locationHistory = gql`
  fragment locationHistory on LocationHistory {
    coordinates {
      ...coordinatesFragment
    }
    created {
      ...userRecordFragment
    }
    speed
    heading
  }
  ${coordinatesFragment}
  ${userRecordFragment}
`;

export const privateCompanySiteFragment = gql`
  fragment privateCompanySiteFragment on CompanySite {
    id
    syncDateTime
    created {
      ...userRecordFragment
    }
    updated {
      ...userRecordFragment
    }
    isArchived
    coordinates {
      ...coordinatesFragment
    }
    address
    name
    geofence
    notes
    privateNotes
    timezone
  }
  ${coordinatesFragment}
  ${userRecordFragment}
`;

export const publicCompanySiteFragment = gql`
  fragment publicCompanySiteFragment on CompanySite {
    id
    syncDateTime
    created {
      ...userRecordFragment
    }
    updated {
      ...userRecordFragment
    }
    isArchived
    coordinates {
      ...coordinatesFragment
    }
    address
    name
    geofence
    notes
    timezone
  }
  ${coordinatesFragment}
  ${userRecordFragment}
`;
