import gql from "graphql-tag";

import { companyFragment } from "../../../fragments";

export default gql`
    mutation superAdminUpdateCompany($id: ID!, $isDisabled: Boolean) {
        superAdminUpdateCompany(id: $id, isDisabled: $isDisabled) {
            ...companyFragment
        }
    }
    ${companyFragment}
`;
