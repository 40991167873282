import { createSelector } from "@reduxjs/toolkit";

const selectCompanyData = createSelector(
  [(state) => Object.values(state.data.data.entities)],
  (data) => {
    let company;
    const customers = {};
    const jobSites = {};
    const users = {};
    data.forEach((item) => {
      // eslint-disable-next-line no-underscore-dangle
      switch (item.__typename) {
        case 'Company': {
          company = item;
          break;
        }
        case "CompanySite": {
          jobSites[item.id] = item;
          break;
        }
        case "CompanyUser": {
          users[item.id] = item;
          break;
        }
        case "Customer": {
          customers[item.id] = item;
          break;
        }
        default:
        // do nothing
      }
    });
    return { company, customers, jobSites, users };
  }
);

export default selectCompanyData;
