import gql from "graphql-tag";

import { companyUserFragment } from "../../../fragments";

export default gql`
  mutation superAdminCreateCompanyUser(
    $firstName: String!
    $lastName: String!
    $role: CompanyUserRoles!
    $companyId: ID!
    $phone: AWSPhone
    $email: AWSEmail
    $unmeteredUsage: Boolean!
  ) {
    superAdminCreateCompanyUser(
      firstName: $firstName
      lastName: $lastName
      role: $role
      companyId: $companyId
      phone: $phone
      email: $email
      unmeteredUsage: $unmeteredUsage
    ) {
      ...companyUserFragment
    }
  }
  ${companyUserFragment}
`;
