import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React, { useEffect, useState } from "react";
import superAdminGetCompanyUserUsernameAlias from "../../api/functions/superAdminGetCompanyUserUsernameAlias";

const useStyles = makeStyles(() => ({
  list: {
    width: "100%",
  },
}));

const UserListItem = ({ user }) => {
  const classes = useStyles();

  const [usernameAlias, setUsernameAlias] = useState("Loading...");

  useEffect(() => {
    if (user) {
      (async () => {
        try {
          setUsernameAlias(
            await superAdminGetCompanyUserUsernameAlias({ userId: user.id })
          );
        } catch (error) {
          setUsernameAlias(error.message || error);
        }
      })();
    }
  }, [user]);

  return (
    <ListItem className={classes.list} alignItems="flex-start">
      <ListItemText
        primary={`${user.firstName} ${user.lastName}`}
        secondary={
          <>
            <Typography variant="body2">{usernameAlias}</Typography>
            <Typography variant="body2">{user.role}</Typography>
          </>
        }
      />
    </ListItem>
  );
};

export default UserListItem;
