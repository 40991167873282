import gql from "graphql-tag";

import {
  companyFragment,
  companyUserFragment,
  companyGroupFragment,
  privateCompanySiteFragment,
  jobFragment,
} from "../../../fragments";

export default gql`
  query scanAdminIndex($after: ID) {
    scanAdminIndex(after: $after) {
      __typename
      nextToken
      items {
        __typename
        ... on Company {
          ...companyFragment
        }
        ... on CompanyGroup {
          ...companyGroupFragment
        }
        ... on CompanySite {
          ...privateCompanySiteFragment
        }
        ... on CompanyUser {
          ...companyUserFragment
        }
        ... on Job {
          ...jobFragment
        }
      }
    }
  }
  ${companyFragment}
  ${companyGroupFragment}
  ${companyUserFragment}
  ${jobFragment}
  ${privateCompanySiteFragment}
`;
