import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Snackbar from "@material-ui/core/Snackbar";

import superAdminCreateCompany from "../../api/functions/superAdminCreateCompany";
import { setCompany } from "../../store/data";
import generateErrorMessage from "../../utils/generateErrorMessage";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    maxWidth: 400,
    width: "100%",
    margin: 16,
  },
  button: {
    marginTop: 16,
    alignSelf: "flex-end",
  },
  textInput: {
    width: "100%",
  },
}));

const NewCompany = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const handleCreateCompany = async () => {
    setLoading(true);
    try {
      const company = await superAdminCreateCompany(name);
      dispatch(setCompany({ company }));
      history.push("/");
    } catch (e) {
      setError(generateErrorMessage(e));
    }
    setLoading(false);
  };

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <form noValidate autoComplete="off">
          <TextField
            disabled={loading}
            className={classes.textInput}
            value={name}
            onChange={(e) => setName(e.target.value)}
            label="Company Name"
            variant="filled"
          />
        </form>
        <Button
          startIcon={loading && <CircularProgress size={16} />}
          className={classes.button}
          variant="contained"
          color="primary"
          disabled={loading}
          onClick={handleCreateCompany}
        >
          Create
        </Button>
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={Boolean(error)}
        autoHideDuration={6000}
        onClose={() => setError(null)}
        message={error}
      />
    </div>
  );
};

export default NewCompany;
