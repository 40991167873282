import { API, graphqlOperation } from "aws-amplify";

import query from "./graphql";
import generateErrorMessage from "../../../utils/generateErrorMessage";

const superAdminQueryDateTimeData = async ({ companyId, from, type, to }) => {
  const results = [];
  let nextToken;
  try {
    do {
      // eslint-disable-next-line no-await-in-loop
      const { data } = await API.graphql(
        graphqlOperation(query, {
          companyId,
          type,
          to,
          from,
          after: nextToken,
        })
      );
      const { superAdminQueryDateTimeData: result } = data;
      results.push(...result.items);
      nextToken = result.nextToken;
    } while (nextToken);
    return results;
  } catch (errors) {
    console.log(errors);
    throw generateErrorMessage("superAdminQueryDateTimeData", errors);
  }
};

export default superAdminQueryDateTimeData;
