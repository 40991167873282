import Divider from "@material-ui/core/Divider";
import Fab from "@material-ui/core/Fab";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import AddIcon from "@material-ui/icons/Add";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { syncCompanies } from "../../store/data";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    maxWidth: 400,
    width: "100%",
    margin: 16,
  },
  fab: {
    right: 20,
    bottom: 20,
    position: "fixed",
  },
  list: {
    width: "100%",
  },
}));

const Home = () => {
  const history = useHistory();
  const classes = useStyles();
  const companies = useSelector((state) =>
    Object.values(state.data.companies.entities).sort((a, b) => a.name.localeCompare(b.name))
  );
  const dispatch = useDispatch();

  const [showDisabled, setShowDisabled] = useState(false);

  useEffect(() => {
    dispatch(syncCompanies());
  }, [dispatch]);

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <FormControlLabel
          control={
            <Switch
              checked={showDisabled}
              color="primary"
              onChange={(event, checked) => setShowDisabled(checked)}
            />
          }
          label="Show disabled"
        />
        <List className={classes.list}>
          {companies
            .filter((company) => showDisabled || !company.isDisabled)
            .map((company) => (
              <div key={company.id}>
                <ListItem
                  className={classes.list}
                  alignItems="flex-start"
                  onClick={() => history.push(`/${company.id}`)}
                  button
                >
                  <ListItemText primary={company.name} secondary={company.id} />
                </ListItem>
                <Divider component="li" />
              </div>
            ))}
        </List>
      </div>
      <Fab
        className={classes.fab}
        color="primary"
        onClick={() => history.push("/new")}
      >
        <AddIcon />
      </Fab>
    </div>
  );
};

export default Home;
