import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Snackbar from "@material-ui/core/Snackbar";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import superAdminCreateCompanyUser from "../../api/functions/superAdminCreateCompanyUser";
import { setData } from "../../store/data";
import generateErrorMessage from "../../utils/generateErrorMessage";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    maxWidth: 400,
    width: "100%",
    margin: 16,
  },
  button: {
    marginTop: 16,
    alignSelf: "flex-end",
  },
  textInput: {
    marginTop: 16,
    width: "100%",
  },
}));

const NewCompany = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { companyId } = useParams();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("Admin");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [unmetered, setUnmetered] = useState(false);

  const handleCreateUser = async () => {
    setLoading(true);
    try {
      const user = await superAdminCreateCompanyUser({
        firstName,
        lastName,
        role,
        phone: phone || null,
        email: email || null,
        companyId,
        unmeteredUsage: unmetered,
      });
      dispatch(setData({ data: user }));
      history.push(`/${companyId}`);
    } catch (e) {
      setError(generateErrorMessage(e));
    }
    setLoading(false);
  };

  const handleUnmetered = (event) => setUnmetered(event.target.checked);

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <form noValidate autoComplete="off">
          <TextField
            disabled={loading}
            className={classes.textInput}
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            label="First Name"
            variant="filled"
          />
          <TextField
            disabled={loading}
            className={classes.textInput}
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            label="Last Name"
            variant="filled"
          />
          <TextField
            disabled={loading}
            className={classes.textInput}
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            label="Phone"
            variant="filled"
            helperText="Of the form +15052709495"
          />
          <TextField
            disabled={loading}
            className={classes.textInput}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            label="Email"
            variant="filled"
            helperText="If adding yourself, use sub addressing: connor+kontra@talarium.io"
          />
          <FormControl variant="filled" className={classes.textInput}>
            <InputLabel>Role</InputLabel>
            <Select value={role} onChange={(e) => setRole(e.target.value)}>
              <MenuItem value="Admin">Admin</MenuItem>
              <MenuItem value="Supervisor">Supervisor</MenuItem>
              <MenuItem value="Employee">Employee</MenuItem>
            </Select>
          </FormControl>
          <FormControlLabel
            control={
              <Checkbox
                checked={unmetered}
                onChange={handleUnmetered}
                name="unmetered"
                color="primary"
              />
            }
            label="Unmetered Usage (use if you don't want customer to be billed for this user's usage i.e. adding yourself to their account)"
          />
        </form>
        <Button
          startIcon={loading && <CircularProgress size={16} />}
          className={classes.button}
          variant="contained"
          color="primary"
          disabled={loading}
          onClick={handleCreateUser}
        >
          Create
        </Button>
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={Boolean(error)}
        autoHideDuration={6000}
        onClose={() => setError(null)}
        message={error}
      />
    </div>
  );
};

export default NewCompany;
